import React from 'react'
import Helmet from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  BannerSmall,
  TextBox,
  CallUs,
  Layout,
  BannerLinks,
  TextImage,
  VideoAndText,
} from '../components'
import {
  faFacebookSquare,
  faLinkedin,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'

import contacts from '../components/CallUs/DefaultContacts'
import boxes from '../components/BannerLinks/DefaultBox'

import erpbanner from '../assets/header.morf.jpg'
import taskimage from '../assets/Auftragsabwicklung.png'
import bookingimage from '../assets/Buchungsjournal.png'
import verwaltungimage from '../assets/Verwaltung.png'
import mobileversionimage from '../assets/mobile.png'
import pcicon from '../assets/pc.png'
import cloudicon from '../assets/cloud.png'
import stickmanicon from '../assets/stickman.png'
import settingsicon from '../assets/settings.png'
import shareicon from '../assets/share.png'
import bubbleicon from '../assets/bubble.png'
import penicon from '../assets/pen.png'
import foldericon from '../assets/folder.png'
import lockicon from '../assets/lock.png'
import linkicon from '../assets/link.png'
import heartbubbleicon from '../assets/heartbubble.png'

import TextIconContainer from '../components/TextIconContainer'

const clouderpIcons = [
  {
    icon: settingsicon,
    title: 'effizientere Prozesse'
  },
  {
    icon: shareicon,
    title: 'mehr Transparenz'
  },
  {
    icon: bubbleicon,
    title: 'bessere Kommunikation'
  },
  {
    icon: cloudicon,
    title: 'mobile Lösung'
  }
]

const aboutIcons = [
  {
    icon: pcicon,
    title: 'Auftragsabwicklung',
    text: 'Erstelle Aufträge mit einem Angebot, plane die entsprechenden Einsätze dafür und verfasse anschließend bequem und einfach die Rechnung'
  },
  {
    icon: stickmanicon,
    title: 'Kundenmanagement',
    text: 'Verwalte alle Kundeninformationen wie Kontaktperson, Adresse, Besprechungsnotizen oder wichtige Informationen über die Kund:innen auf einen Blick und ordne ihm Projekte zu'
  },
  {
    icon: penicon,
    title: 'Zeiterfassung',
    text: 'Erfasse die Arbeitszeiten deiner Mitarbeiter sowie aufgewendete Zeiten für \n ein bestimmtes Projekt.'
  },
  {
    icon: foldericon,
    title: 'Projektorganisation',
    text: 'Organisiere und verwalte Projekte, gruppiere sie durch Unterprojekte und sammle wichtige Informationen und Unterlagen zentral \n an einem Ort'
  }
]

const customerpIcons = [
  {
    icon: lockicon,
    title: 'Anpassung an betriebsinterne \n Prozesse'
  },
  {
    icon: linkicon,
    title: 'Ihre individuelle und maßgeschneiderte \n ERP-Lösung'
  },
  {
    icon: heartbubbleicon,
    title: 'Zusammenarbeit \n mit den Kund:innen'
  }
]

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Helmet
        title="HW-ERP"
        meta={[
          {
            name: 'description',
            content:
              'Ihre individuell anpassbare, cloud-basierte ERP Software Lösung. Von Auftragsverwaltung bis Zeiterfassung, unser Tool bietet ein zentrales System für die Organisation und Optimierung Ihres Unternehmens. '
          },
          {
            name: 'keywords',
            content:
              'webagentur innsbruck, digitale agentur tirol, online agentur, hw-erp, individualsofware, momo'
          },
          {
            property: 'og:description',
            content:
            'Ihre individuell anpassbare, cloud-basierte ERP Software Lösung. Von Auftragsverwaltung bis Zeiterfassung, unser Tool bietet ein zentrales System für die Organisation und Optimierung Ihres Unternehmens. '
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/header.morf-e49dd868ec9337a9793fc469f9053733.jpg'
          }
        ]}
      />
      <BannerSmall 
        align="top"
        image={erpbanner} 
        title="HW-ERP" 
        text= {
          <>
            <p>Ihre individuell anpassbare, cloud-basierte ERP Software Lösung. Von Auftragsverwaltung bis Zeiterfassung, unser Tool bietet ein zentrales System für die Organisation und Optimierung Ihres Unternehmens.</p>
            <h3>SAP? - Wird hier nicht mehr gebraucht!</h3>
          </>
        }
        style={{minHeight:'650px'}}
        tooBright={true}
      />
      <TextBox
        title=""
        text= {
          <span>
            <h3>
              Was ist ein ERP Cloud System?
            </h3>
          </span>
        }
      />
      
      <TextIconContainer textIcon={clouderpIcons} />

      <TextBox 
        title=""
        text="Enterprise Resource Planning - oder auch kurz ERP genannt - ist eine Software zur Verwaltung und Ressourcenplanung eines Unternehmens. Es bringt Struktur und Transparenz in betriebsinterne Prozesse und hilft dabei, diese zu optimieren. Bei einem cloud-basierten ERP-System werden die Anwendungen in einer Cloud zur Verfügung gestellt. Im Gegensatz zu on-premise Lösungen hat das den Vorteil, dass das System nicht vom Unternehmen selbst gehostet werden muss und gleichzeitig von jedem beliebigen Ort auf das System zugegriffen werden kann."
        align="left"
      />

      <TextBox 
        title= {
          <span>
            <h3>
              Über HW-ERP
            </h3>
          </span>
        }
      />

      <TextIconContainer textIcon={aboutIcons} />

      <TextImage
        title="Von Projektorganisation zur Auftragsabwicklung"
        text={
          <span>
            <ul>
              <li>
                Projekt anlegen und zugehörige Dokumente, Adressen und Informationen an einem Ort speichern
              </li>
              <li>
                für jedes Projekt können verschiedene Angebote mit unterschiedlichen Leistungen und Positionen erstellt werden
              </li>
              <li>
                Planen sie die Einsätze für Ihr Projekt und erfassen anschließend das Ausmaß 
              </li>
              <li>
                Am Ende kann bequem und einfach die Rechnung gestellt werden
              </li>
            </ul>
          </span>
        }
        image={taskimage}
      />
      <TextImage
        title="Buchungsjournal und Zeiterfassung"
        text={
          <span>
            <ul>
              <li>
                Im Buchungsjournal können die Arbeitszeiten der Mitarbeiter auf den Einsätzen, deren Absenzen oder Verwaltungsaufwand erfasst werden
              </li>
              <li>
                Ebenso können der Material- Maschinen- und Fahrzeuggebrauch auf den Einsätzen eingetragen werden
              </li>
              <li>
                In einer Wochenübersicht <br /> können die Arbeitszeiten der Mitarbeiter eingesehen werden 
              </li>
              <li>
                In einer Übersicht werden alle Zeit-, Material-, Maschinen- und Fahrzeug-Buchungen aufgelistet
              </li>
            </ul>
          </span>
        }
        imagepos="left"
        image={bookingimage}
      />
      <TextImage
        title="Verwaltung"
        text={
          <span>
            <ul>
            <li>
                Mitarbeiter, Maschinen, Fahrzeuge und Material erfassen und verwalten
              </li>
              <li>
                Übersicht und wichtige Informationen <br /> auf einen Blick
              </li>
              <li>
                Organisieren und Verwalten <br /> sie ihre Ressourcen
              </li>
            </ul>
          </span>
        }
        image={verwaltungimage}
      />
      <TextImage
        title="Mobile Version"
        text={
          <span>
            <ul>
              <li>
                praktische Mobile-Version für’s <br /> Handy oder Tablet
              </li>
              <li>
                Wichtige Informationen und Daten vor Ort auf der Baustelle einsehen und bearbeiten
              </li>
              <li>
                Einsatzplanung in der Hosentasche
              </li>
            </ul>
          </span>
        }
        imagepos="left"
        image={mobileversionimage}
      />
      <VideoAndText
        YTvideo="7KRK-iy0Hps"
        headline=""
        text=""
        nobackground
      />
      <TextBox
        title= {
          <span>
            <h3>
              Custom-ERP? Wir helfen!
            </h3>
          </span>
        }
      />
      <TextIconContainer textIcon={customerpIcons} />
      <TextBox title="" align="left" text="Als innovativer Partner bringen wir ein breites Spektrum an technischer Lösungskompetenz und strategische Beratung zur gemeinsamen Entwicklung mit. So können wir zusammen die Software an ihre Wünsche anpassen, um eine optimale und nachhaltige Lösung zu finden. Wir freuen uns darauf, unser Know-How in dieses Projekt einfließen zu lassen und mit euch eine maßgeschneiderte Individualsoftware umzusetzen. " />
      <TextBox 
        title= {
          <span>
            <h3>
              Förderung
            </h3>
          </span>
        }
        text={
          <span>
            <p>
              Das Projekt kann über die Tiroler Digitalisierungsförderung mit bis zu 50% für Konzeption und Schulung unterstützt werden. Nähere Infos unter 
              <a href="https://www.tirol.gv.at/arbeit-wirtschaft/wirtschaft-und-arbeit/foerderungen/technologiefoerderungsprogramm/digitalisierungsfoerderungen/tiroler-digitalisierungsfoerderung"> tirol.gv.at </a>
            </p>
          </span>
        }
        align="left"
      />
      <TextBox 
        title= {
          <span>
            <h3>
              Demo anfordern
            </h3>
          </span>
        }
        text="" 
        buttons={[
          {link: "/kontakt", text: "Kontaktformular"}
        ]}
        buttonalign="left"
      />
      <CallUs contacts={contacts} title="Rufen Sie uns an!" />
      <TextBox
        title="Walk with us on the holzweg"
        text={
          <span>
            <p>
              Ready für uns? Dann kontaktiere uns zu einem unverbindlichen
              Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn
              der holzweg nach Traumjob-Garantie klingt, dann schaue bei den
              Stellenangeboten rein oder besuche uns in den sozialen Medien!
            </p>
            <p>
              <a
                href="https://www.facebook.com/holzwegwebagentur"
                className="social-icon"
                rel="nofollow"
                target="_blank"
                style={{ color: '#ec6318' }}
              >
                <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
              </a>
              &nbsp;
              <a
                href="https://www.linkedin.com/company/holzweg-e-commerce-solutions"
                rel="nofollow"
                className="social-icon"
                target="_blank"
                style={{ color: '#f08607' }}
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              &nbsp;
              <a
                href="https://www.youtube.com/user/holzwegagentur"
                className="social-icon"
                rel="nofollow"
                target="_blank"
                style={{ color: '#f4a301' }}
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </p>
          </span>
        }
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default IndexPage
